%iwrap {
  display: block;
  position: relative;
  &::before {
    content: '';
    display: block;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: 50%;
  }
}
$iwrap-list: (
  100: 100%,
  50: 50%,
  66: 66.666%,
  56: 56.666%
);
.iwrap {
  @extend %iwrap;
}
@each $key, $val in $iwrap-list {
  .iwrap-#{$key} {
    @extend %iwrap;
    &::before {
      padding-bottom: #{$val};
    }
  }
}
.iwrap-contain {
  img {
    object-fit: contain;
  }
}
.iwrap-maxwidth {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
