.navigation {
  display: flex;
  margin-bottom: 30px;
  &__cate {
    display: block;
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 5px;
    background-color: #ebebeb;
    color: $gray-800;
    margin-right: 10px;
    font-weight: bold;
    strong {
      font-weight: 700;
    }
    &:hover {
      background-color: $border-color;
      text-decoration: none;
      color: $gray-800;
    }
    &.active {
      &,
      &:hover {
        background-color: $blue;
        color: $white;
      }
    }
  }
  &__btn {
    display: none;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 4px 0;
    list-style: none;
  }
  &__item {
    padding: 4px 5px;
  }
  &__link {
    display: block;
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 5px;
    background-color: #ebebeb;
    color: $gray-800;
    &:hover {
      background-color: $border-color;
      text-decoration: none;
      color: $gray-800;
    }
    &.active {
      &,
      &:hover {
        background-color: $blue;
        color: $white;
      }
    }
  }
  @media (max-width: 991px) {
    padding-top: 10px;
    flex-wrap: wrap;
    &__collapse {
      display: none;
      width: 100%;
    }
    &__list {
      flex-direction: column;
    }
    &__btn {
      display: inline-block;
      height: 35px;
      width: 40px;
      font-size: 14px;
      padding: 7px 14px;
      border-radius: 4px;
      border: 0;
      background-color: $blue;
      position: relative;
      span {
        display: inline-block;
        width: 14px;
        height: 2px;
        background-color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: .3s;
        &:nth-child(2) {
          transform: translate(-50%, -50%) rotateZ(90deg) rotateX(0);
        }
      }
      &.active span:nth-child(2) {
        transform: translate(-50%, -50%) rotateZ(90deg) rotateX(90deg);
      }
    }
  }
}

.service {
  &__iwrap {
    width: 105px;
    margin-right: 15px;
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-bottom: 66.67%;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  &__title {
    font-size: 14px;
    color: #333;
    &:hover {
      color: $blue;
    }
  }
  @media (max-width: 575px) {
    &--2 {
      flex-wrap: wrap;
    }
    &--2 &__iwrap {
      width: 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}

.service-slider {
  // background-color: #f5f5f5;
  border: 1px solid $border-color;
  border-radius: 10px;
  padding: 0 30px 30px;
  margin-bottom: 50px;
  &__header {
    display: flex;
    padding: 20px 0;
    align-items: center;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: auto;
  }
  &__prev,
  &__next {
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $blue;
    &:focus {
      outline: 0;
    }
  }
  &__prev {
    margin-right: 10px;
  }
  @media (max-width: 575px) {
    padding: 0 15px 15px;
  }
}

.news {
  &__iwrap {
    width: 156px;
    margin-right: 20px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 66.67%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
    color: $blue;
    margin-bottom: 6px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
  &__link {
    display: inline-block;
    color: $blue;
    margin-top: 4px;
    position: relative;
    top: 2px;
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
  &__desc {
    text-align: justify;
  }
}

.tags {
  border: 1px solid $border-color;
  padding-left: 77px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 24px;
  &__title {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    line-height: 38px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    margin: 0 -10px;
  }
  &__list-item {
    padding: 0 10px;
  }
  &__link {
    font-size: 14px;
    line-height: 38px;
    color: #999999;
    text-decoration: underline;
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}

.partner-slider {
  margin-bottom: 30px;
  .swiper-slide {
    height: auto;
    align-self: center;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.category {
  margin-bottom: 30px;
  &__media {
    align-items: center;
  }
  &__iwrap {
    width: 186px;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: .3s;
    }
    &:hover img {
      transform: scale(1.05);
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    transition: .3s;
    &:hover {
      color: $blue;
    }
  }
  &__list {
    list-style: none;
    margin-bottom: 0;
    padding: 10px 0;
  }
  &__list-item {
    border-bottom: 1px dashed $border-color;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__link {
    display: block;
    font-size: 14px;
    line-height: 29px;
    color: $gray-800;
    transition: .3s;
    &:hover {
      background-color: #f7f7f7;
      text-decoration: none;
      color: $blue;
      padding-left: 10px;
    }
  }
  @media (max-width: 575px) {
    &__media {
      flex-direction: column;
    }
    &__iwrap {
      width: 100%;
      margin-right: 0;
    }
    &__title {
      width: 100%;
      margin-top: 8px;
    }
    &__link {
      &:hover {
        padding-left: 0;
      }
    }
  }
}
