.header {
  position: relative;
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  &.is-fixed {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  }
  &__inner {
    display: flex;
    align-items: center;
    padding: 24px 0;
    position: relative;
    height: 122px;
  }
  &__logo {
    display: block;
    img {
      display: block;
      height: 60px;
    }
  }
  &__menu-btn {
    display: inline-block;
    font-size: 26px;
    color: $blue;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: 0;
    background: 0;
  }
  &__search {
    padding: 16px 20px;
  }
  &__search-btn {
    display: none;
  }
  &__nav {
    flex-direction: column;
    background-color: rgba($white, 0.95);
    padding: 10px 0;
    margin-top: 20px;
    margin-bottom: 30px;
    .nav-link {
      color: $gray-800;
      font-weight: 600;
    }
  }
  &__lang {
    display: flex;
    padding: 8px 16px;
  }
  &__lang-item {
    margin-right: 16px;
    img {
      height: 24px;
    }
  }
  @media (min-width: 992px) {
    &__lang-item {
      margin-right: 8px;
      img {
        height: 16px;
      }
    }
    &__inner {
      justify-content: space-between;
    }
    &__menu-btn {
      display: none;
    }
    &__search-btn {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: -6px;
      transform: translateY(-50%);
      background: 0;
      border: 0;
      margin-top: 11px;
      &:hover {
        color: $blue;
      }
    }
    &__search {
      display: none;
      padding: 0;
      position: absolute;
      top: calc(100% - 36px);
      right: 0;
      z-index: $zindex-sticky;
      &::before {
        content: '';
        display: block;
        width: 50px;
        height: 30px;
        position: absolute;
        bottom: 100%;
        right: -10px;
      }
    }
    &__nav {
      flex-direction: row;
      background: 0;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 0;
      right: -24px;
      .nav-item {
        position: relative;
      }
      .nav-link {
        font-weight: 400;
        font-size: 13px;
        padding: 8px 12px;
        &:hover {
          color: $blue;
        }
      }
    }
    &__lang {
      padding: 7px 16px;
    }
  }
}

.navbar {
  .navbar-nav {
    .nav-item {
      & > .dropdown-menu {
        margin: 0 8px;
      }
    }
    .nav-link {
      font-size: 16px;
      font-weight: 600;
      font-family: $open-sans;
      padding: 16px 20px;
      color: $white;
      text-transform: uppercase;
    }
  }
  @media (min-width: 992px) {
    .navbar-nav {
      margin-right: 30px;
      margin-top: 21px;
      .nav-item {
        position: relative;
      }
      .nav-link {
        color: $black;
        font-size: 14px;
        text-transform: none;
        padding: 16px;
        font-weight: 400;
        &:hover,
        &.active {
          color: $blue;
        }
      }
    }
  }
}

.search {
  .form-control {
    border-right: 0;
    &:focus {
      box-shadow: unset;
      & + .input-group-append .input-group-text {
        border-color: lighten($primary, 20%);
      }
    }
  }
  .input-group-text {
    background-color: $white;
  }
}
