.page-link {
  border-radius: .25rem !important;
  color: $gray-800;
  padding: 8px 16px;
}
.page-item {
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.active .page-link {
    background-color: $gray-800;
    border-color: $gray-800;
  }
}

@media (max-width: 767px) {
  .page-link {
    padding: 6px 10px;
  }
}
@media (max-width: 370px) {
  .page-link {
    padding: 4px 7px;
  }
}
