.over-hidden {
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.color-default {
  &,
  &:hover {
    color: inherit;
  }
}
@media (max-width: 575px) {
  .gutter-sm-10 {
    margin: 0 -15px;
    & > [class^="col"] {
      padding: 0 5px;
    }
  }
}
