.footer {
  background-color: #f5f5f5;
  &__block {
    margin-bottom: 22px;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3px;
    transition: .3s;
    &:hover {
      color: $blue;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__link {
    display: block;
    color: $gray-800;
    font-size: 14px;
    line-height: 28px;
    position: relative;
    left: 0;
    transition: .3s;
    &:hover {
      color: $blue;
      text-decoration: none;
      left: 5px;
    }
  }
  &__copyright {
    text-align: center;
    padding: 18px 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    margin-bottom: 25px;
  }
  &__name {
    font-size: 14px;
    font-weight: 600;
    color: $black;
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
  @media (max-width: 991px) {
    &__header {
      flex-direction: column;
      align-items: center;
      padding-top: 12px;
    }
    &__name {
      margin-bottom: 8px;
      padding-bottom: 12px;
      text-transform: uppercase;
      font-size: 16px;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 80px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.f-contact {
  margin-bottom: 16px;
  a {
    white-space: nowrap;
    color: $gray-800;
    &:hover {
      font-weight: 600;
      color: $blue;
      text-decoration: none;
    }
  }
  p {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
}

.f-connect {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 16px;
  &__list {
    display: flex;
    list-style: none;
    margin-top: 28px;
  }
  &__item {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    display: block;
    width: 40px;
    height: 40px;
    color: inherit;
    // border: 1px solid currentColor;
    border-radius: 50%;
    position: relative;
    transition: .3s;
    &:hover {
      color: inherit;
      background-color: currentColor;
    }
    .fa {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: inherit;
    }
    &:hover .fa {
      color: $white;
    }
  }
  @media (max-width: 767px) {
    align-items: center;
    margin-bottom: 0;
    &__list {
      padding-left: 0;
      margin-top: 10px;
    }
  }
}

.f-category {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  &__item {
    padding: 0 16px;
    position: relative;
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
    &:first-child {
      padding-left: 0;
    }
  }
  &__link {
    color: $black;
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
  @media (max-width: 575px) {
    width: 100%;
    flex-direction: column;
    &__item {
      width: 100%;
      padding: 0 0 !important;
      &::after {
        display: none;
      }
    }
    &__link {
      display: block;
      width: 100%;
      padding: 6px 16px;
      text-align: center;
    }
  }
}
