body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding-top: 122px;
  & > * {
    font-size: 14px;
  }
}
img {
  max-width: 100%;
}
button {
  cursor: pointer;
}
a,
button,
input,
textarea,
select {
  &:focus {
    outline: 0;
  }
}

a:hover {
  color: $blue;
  text-decoration: none;
}
