.appointment {
  margin-bottom: 30px;
  .form-group {
    margin-bottom: 26px;
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  &__subtitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: $blue;
    margin-top: 10px;
    margin-bottom: 18px;
  }
  &__text {
    margin-bottom: 50px;
    a,
    a:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
  &__btn {
    &,
    &:hover {
      background-color: $blue;
    }
  }
}
