.request-form {
  margin-bottom: 30px;
  .form-group {
    margin-bottom: 26px;
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
    a {
      &,
      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }
  }
  &__checkbox {
    a {
      &,
      &:hover {
        color: $blue;
      }
    }
  }
  &__btn {
    padding-left: 60px;
    padding-right: 60px;
    &,
    &:hover {
      background-color: $blue;
    }
  }
}
