.aside {
  &__block {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  &__title {
    padding: 20px 15px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
  }
  &__list {
    width: 100%;
    margin-bottom: 0;
    padding: 0 15px;
    list-style: none;
  }
  &__item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.as-info {
  border: 0;
  &__iwrap {
    border-radius: 5px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
    }
  }
  &__body {
    padding: 25px 10px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: $black;
    a {
      color: #333;
      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}

.as-search {
  padding: 0 15px 15px;
  &__desc {
    color: #666666;
    margin-top: 15px;
  }
}
