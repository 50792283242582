.news-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  margin-bottom: 30px;
  &__item {
    padding: 15px 0;
  }
}

.news-intro {
  &__title {
    font-size: 20px;
    font-weight: bold;
    color: $black;
    margin-bottom: 16px;
  }
  &__desc {
    margin-bottom: 15px;
    text-align: justify;
  }
}

.news-actions {
  &__link {
    display: block;
    background-color: #924c00;
    color: $white;
    text-align: center;
    padding: 10px;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.news-filter {
  display: inline-flex;
  white-space: nowrap;
  border-radius: 5px;
  padding: 4px 10px 4px 2px;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #999;
    background: linear-gradient(to top, #222, #f7f7f7);
    opacity: 0.2;
  }
  .fa {
    width: 42px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #999;
    margin-right: 10px;
  }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: -2;
    opacity: 0;
  }
}

.post {
  &-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &-info {
    margin-bottom: 10px;
    padding-bottom: 10px;
    .fa {
      color: #666;
    }
  }
  &-sapo {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: justify;
  }
  &-content {
    text-align: justify;
    strong {
      font-weight: 600;
    }
    a {
      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }
  }
  &-suggest {
    ul {
      border: 1px dashed $blue;
      border-left: 10px solid $blue;
      background-color: #f6f6f6;
      padding: 10px 0 10px 20px;
      margin-bottom: 16px;
      list-style: none;
    }
    li {
      padding: 3px 0;
      padding-left: 16px;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background: url('../images/icon-th-blue.png') center/contain no-repeat;
        position: absolute;
        top: 12px;
        left: 0;
      }
      a {
        display: block;
        font-size: 14px;
        line-height: 24px;
        color: $blue;
      }
    }
  }
}

.relate {
  padding-top: 20px;
  margin-bottom: 24px;
  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  li {
    padding: 3px 0;
    padding-left: 16px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background: url('../images/icon-th-blue.png') center/contain no-repeat;
      position: absolute;
      top: 12px;
      left: 0;
    }
    a {
      display: block;
      font-size: 14px;
      line-height: 24px;
      color: $blue;
    }
  }
}
