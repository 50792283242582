.ct-info {
  margin-bottom: 30px;
  &__title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__content {
    line-height: 30px;
    margin-bottom: 38px;
    a {
      color: $gray-800;
      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }
  }
  &__map {
    height: 216px;
  }
}
.ct-form {
  margin-bottom: 30px;
  &__btn {
    &,
    &:hover {
      background-color: $blue;
    }
  }
}
