.banner-frame {
  width: 100%;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 0.3;
  }
}

.banner-slider {
  .swiper-slide {
    align-self: stretch;
    height: auto;
    overflow: hidden;
    img {
      display: block;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
      transition: all 7s ease-out;
      transform: scale(1.01);
    }
  }
  &.zoom .swiper-slide img {
    transform: scale(1.11);
  }
  .swiper-pagination {
    position: absolute;
    right: 0;
    bottom: 30px;
  }
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin-left: 15px;
    background-color: #fff;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: $blue;
    }
  }
  @media (max-width: 767px) {
    .swiper-pagination {
      bottom: 8px;
    }
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
    .swiper-slide {
      img {
        min-height: 30vh;
      }
    }
  }
}

.intro-banner {
  margin-bottom: 30px;
  overflow: hidden;
  &__text {
    color: $white;
    font-size: 18px;
    line-height: 35px;
    display: flex;
    flex-direction: column;
    min-height: 170px;
    justify-content: center;
    &::before {
      content: '';
      display: block;
      width: 50vw;
      height: 100%;
      background: url('../images/intro-banner-text-bg.png') center/cover no-repeat;
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
    }
    & > *:first-child {
      font-size: 22px;
    }
    a,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  &__brand {
    display: flex;
    align-items: center;
    padding: 0;
    min-height: 170px;
    &::before {
      content: '';
      display: block;
      width: 50vw;
      height: 100%;
      background: url('../images/intro-banner-brand-bg.png') center/cover no-repeat;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
    }
  }
  .swiper-container {
    margin-left: 10px;
  }
  .swiper-slide {
    height: auto;
    align-items: stretch;
  }
  &__iwrap {
    display: block;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: $white;
    img {
      display: block;
      width: 100%;
      height: 82px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
  @media (max-width: 991px) {
    .swiper-container {
      margin-left: 0;
    }
    &__text {
      min-height: 100px;
      &::before {
        width: 100vw;
        right: 50%;
        transform: translateX(50%);
      }
    }
    &__brand {
      min-height: 100px;
      padding: 9px 0;
      &::before {
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media (max-width: 575px) {
    .swiper-container {
      margin: 0 10px;
    }
    &__text {
      font-size: 14px;
      line-height: 28px;
      span:first-child {
        font-size: 18px;
      }
    }
    &__brand {
      flex-wrap: wrap;
      padding: 0 !important;
      margin: 0 -15px;
      width: calc(100% + 30px);
    }
    &__iwrap {
      padding: 0 5px;
    }
  }
}
