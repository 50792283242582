// style the checkbox
.checkbox-styled {
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
  }
  &:hover &__icon {
    border-color: $primary;
  }
  &__input:checked + &__icon {
    border-color: $primary;
    &::before {
      content: '\f00c';
      font-family: 'fontawesome';
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 13px;
    }
  }
}

// style the radio
.radio-styled {
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0;
  }
  &:hover &__icon {
    border-color: $primary;
  }
  &__input:checked + &__icon {
    border-color: $primary;
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $primary;
      border-radius: 50%;
    }
  }
}

// product or cart page quantity
.product-quantity {
  width: 110px;
  .form-control {
    text-align: center;
    font-size: 14px;
    &:focus {
      box-shadow: unset;
    }
  }
  .input-group-text {
    font-size: 9px;
    background-color: #fff;
  }
}
