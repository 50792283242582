// navbar mobile execution
.navbar-backdrop {
  display: none;
  @media (max-width: 1199px) {
    display: block;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background-color: black;
    opacity: .0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -100%;
    transition: left 0s .3s, opacity .3s;
    &.is-show {
      left: 0;
      opacity: .5;
      transition: left 0s, opacity .3s;
    }
  }
}
.navbar {
  padding: 0;
}
.navbar-mobile {
  display: block;
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 100vh;
  overflow-y: auto;
  background-color: $gray-800;
  transition: .3s;
  align-items: flex-start;
  &.is-show {
    left: 0;
  }
  .dropdown-menu {
    padding-left: 15px;
  }
}
@each $key, $val in $grid-breakpoints {
  @media (min-width: #{$val}) {
    .navbar-mobile.navbar-expand-#{$key} {
      position: static;
      background: none;
      z-index: 0;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      overflow: visible;
      transition: unset;
      .dropdown-menu {
        padding-left: 0;
      }
    }
  }
}
